import { apiSlice } from "../apiSlice";
import QueryString from "qs";

export const autocomxApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDo: builder.query({
      query: (filter) => ({
        url: `/operaciones?filter=` + filter,
        method: "GET",
      }),
    }),
    getClient: builder.query({
      query: (filter) => ({
        url: `/clientes`,
        params: `per_page=10&fields=CLIIDXXX,CLINOMXX&${filter}`,
        method: "GET",
      }),
    }),
    getEmployee: builder.query({
      query: (filter) => ({
        url: `/empleados?per_page=10&fields=USRIDXXX,EMPNOCOX&filters[REGESTXX]=ACTIVO&filters[EMPNOCOX][LIKE]=%${filter}%`,
        method: "GET",
      }),
    }),
    getTransporter: builder.query({
      query: (filter) => ({
        url: `/transportadoras?per_page=10&fields=TRAIDXXX,TRADESXX&filters[REGESTXX]=ACTIVO&filters[TRADESXX][LIKE]=%${filter}%`,
        method: "GET",
      }),
    }),
    getProvider: builder.query({
      query: (filter) => ({
        url: `proveedores`,
        params: `per_page=10&fields=PIEIDXXX,PIENOMXX&${filter}`,
        method: "GET",
      }),
    }),
    getProviderRepre: builder.query({
      query: (filter) => ({
        url: `proveedoresRepre`,
        params: `per_page=10&fields=PROITIDX,PROITDES&${filter}`,
        method: "GET",
      }),
    }),
    getAgent: builder.query({
      query: (filter) => ({
        url: `agentes?per_page=10&fields=AGEIDXXX,AGEDESXX&${filter}`,
        method: "GET",
      }),
    }),
    getLineaNaviera: builder.query({
      query: (filter) => ({
        url: `navieras?per_page=10&fields=LINIDXXX,LINDESXX&${filter}`,
        method: "GET",
      }),
    }),
    getOpMap: builder.query({
      query: (filter) => ({
        url: `/operacionMap?filter=` + filter,
        method: "GET",
      }),
    }),
    getBkMap: builder.query({
      query: (filter) => ({
        url: `/reservacionMap?filter=` + filter,
        method: "GET",
      }),
    }),
    getQtMap: builder.query({
      query: (filter) => ({
        url: `/cotizacionMap?filter=` + filter,
        method: "GET",
      }),
    }),
    getPoMap: builder.query({
      query: (filter) => ({
        url: `/posicionMap?filter=` + filter,
        method: "GET",
      }),
    }),
    getDoByClient: builder.query({
      query: (filter) => ({
        url: `/operationsByClient/${filter.CLIIDXXX}/${filter.value}`,
        method: "GET",
      }),
    }),
    getProviderByClient: builder.query({
      query: (filter) => ({
        url: `/provedorByClients/${filter.CLIIDXXX}/${filter.value}`,
        method: "GET",
      }),
    }),
    getCargos: builder.query({
      query: (filter) => ({
        url: `/cargos?per_page=10&fields=CARIDXXX,CARDESXX&filters[REGESTXX]=ACTIVO&filters[CARDESXX][LIKE]=${filter}%`,
        method: "GET",
      }),
    }),
    getUser: builder.query({
      query: (filter) => {
        const encodedFilter = QueryString.stringify({
          'filters[USRNOMXX][LIKE]': `%${filter}%`
        }, { encode: true });
        return {
          url: `/usuarios?per_page=20&fields=USRIDXXX,USRNOMXX&filters[REGESTXX]=ACTIVO&${encodedFilter}`,
          method: "GET",
        };
      },
    }),
    getReferencia: builder.query({
      query: (filter) => ({
        url: `/referencia`,
        params: `per_page=10&fields=cliidxxx,prorefxx,prodesxx&filters[cliidxxx]=${filter.CLIIDXXX}&filters[regestxx]=ACTIVO&filters[prorefxx][LIKE]=${filter.value}%`,
        method: "GET",
      }),
    }),
    getManifiesto: builder.query({
      query: (filter) => ({
        url: `/manifiesto`,
        params: `fields=maniidxx,manititu&filters[maniidxx][LIKE]=${filter}`,
        method: "GET",
      }),
    }),
    getComprobante: builder.query({
      query: (filter) => ({
        url: `/comprobante`,
        params: `fields=manicomp,manititu&filters[manicomp][LIKE]=${filter}`,
        method: "GET",
      }),
    }),
    getManifiestosByComprobante: builder.query({
      query: (filter) => ({
        url: `/manifiestosByComprobante`,
        params: `fields=maniidxx,manicomp,manititu&filters[manicomp]=${filter}`,
        method: "GET",
      }),
    }),
    getDocTransRis: builder.query({
      query: (filter) => {
        const encodedFilter = QueryString.stringify({
          'filters[dtrdtrxx][LIKE]': `%${filter}%`,
          '&filters[dtridxxx][LIKE]': `%${filter}%`
        }, { encode: true });
        return {
          url: `/detalleDocTraRis?per_page=20&fields=dtrdtrxx,dtridxxx&filters[REGESTXX]=ACTIVO&${encodedFilter}`,
          method: "GET",
        };
      },
    }),
    getInfoSalidaRis: builder.query({
      query: (filter) => {
        const encodedFilter = QueryString.stringify({
          'filters[saldtrxx][LIKE]': `%${filter}%`,
          '&filters[salidxxx][LIKE]': `%${filter}%`
        }, { encode: true });
        return {
          url: `/salidaDeCargaRis?per_page=20&fields=saldtrxx,salidxxx&filters[REGESTXX]=5&${encodedFilter}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useLazyGetDoQuery } = autocomxApiSlice;
export const { useLazyGetClientQuery } = autocomxApiSlice;
export const { useLazyGetEmployeeQuery } = autocomxApiSlice;
export const { useLazyGetTransporterQuery } = autocomxApiSlice;
export const { useLazyGetProviderQuery } = autocomxApiSlice;
export const { useLazyGetProviderRepreQuery } = autocomxApiSlice;
export const { useLazyGetAgentQuery } = autocomxApiSlice;
export const { useLazyGetLineaNavieraQuery } = autocomxApiSlice;
export const { useLazyGetOpMapQuery } = autocomxApiSlice;
export const { useLazyGetBkMapQuery } = autocomxApiSlice;
export const { useLazyGetQtMapQuery } = autocomxApiSlice;
export const { useLazyGetPoMapQuery } = autocomxApiSlice;
export const { useLazyGetDoByClientQuery } = autocomxApiSlice;
export const { useLazyGetProviderByClientQuery } = autocomxApiSlice;
export const { useLazyGetCargosQuery} = autocomxApiSlice;
export const { useLazyGetUserQuery} = autocomxApiSlice;
export const { useLazyGetReferenciaQuery } = autocomxApiSlice;
export const { useLazyGetManifiestoQuery } = autocomxApiSlice;
export const { useLazyGetComprobanteQuery } = autocomxApiSlice;
export const { useLazyGetManifiestosByComprobanteQuery } = autocomxApiSlice;
export const { useLazyGetDocTransRisQuery } = autocomxApiSlice;
export const { useLazyGetInfoSalidaRisQuery } = autocomxApiSlice;
