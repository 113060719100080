import React, { useState, useEffect, useRef } from "react";

//bootstrap
import { Form, Row, Col, Button } from "react-bootstrap";

//import redux
import { getPermissionUser } from "../../libs/redux/slices/authSlice/authSlice";
import { addArrayToQueue, setInProgress, setEmpnitxx } from "../../libs/redux/slices/queueSlice/queueSlice";
import { getDataUser } from "../../libs/redux/slices/authSlice/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { setShowErrors, setObjectErrors } from "../../libs/redux/slices/errorSlice/errorSlice";

import fileSlice from "../../libs/localforage/slices/fileSlice/fileSlice";
import { getPagesSplit, resetPagesSplit } from "../../libs/redux/slices/splitpdfSlice/splitpdfSlice";
import { setAreaSelected, setTypeSelected, setSerieSelected} from "../../libs/redux/slices/filterSlice/filterSlice";
//import create metadata
import Metadata from "../Metadata";
import Splitpdf from "../Splitpdf/Splitpdf";
import { countPages } from "../Splitpdf/numPages";
import { useSearchParams, useNavigate } from 'react-router-dom'

const Selectop = () => {
  const [params] = useSearchParams();
  const fileClass = async () => await new fileSlice().createIndexFile();
  const [indexFile, setIndexFile] = useState();
  const [validated, setValidated] = useState(false);
  const dataPermission = useSelector(getPermissionUser);
  const dataUser = useSelector(getDataUser);
  const [company, setCompany] = useState(params.get("EMPNITXX") ? params.get("EMPNITXX") : "");
  const [areas, setAreas] = useState({});
  const [area, setArea] = useState(params.get("AREAIDXX") ? params.get("AREAIDXX") : "");
  const [series, setSeries] = useState({});
  const [serie, setSerie] = useState(params.get("SERIEIDX") ? params.get("SERIEIDX") : "");
  const [types, setTypes] = useState({});
  const [type, setType] = useState(params.get("TIPOIDXX") ? params.get("TIPOIDXX") : "");
  const [metadatas, setMetadatas] = useState({});
  const [jsonmetx, setJsonmetx] = useState(JSON.parse(params.get("JSONMETX")) ? JSON.parse(params.get("JSONMETX")) : {});
  const [file, setFile] = useState([]);
  const [contentType, setContentType] = useState([]);
  const [base64, setBase64] = useState([]);
  const dispatch = useDispatch();
  const [isSplitPdf, setIsSplitPdf] = useState(false);
  const pagesSplit = useSelector(getPagesSplit);
  const [formData, setFormData] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const fileInputRef = useRef(null);
  const [isPdf, setIsPdf] = useState(false);
  const [inputValue, setInputValue] = useState(JSON.parse(params.get("JSONMETX")) ? JSON.parse(params.get("JSONMETX")) : {});
  const [disableButton, setDisableButton] = useState(false);
  const [listPages, setListPages] = useState({});
  const [useParams, setUseParams] = useState(params.size > 0 ? true : false);
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof indexFile === "undefined") {
      fileClass().then((response) => setIndexFile(response));
    }
  }, [indexFile]);

  const updateJsonmetx = (key, value, label) => {
    const newJsonmetx = { ...jsonmetx };
    newJsonmetx[key] = value;
    setJsonmetx(newJsonmetx);
    const newImputs = { ...inputValue };
    switch (key) {
      case "CLIIDXXX":
      case "DOCNROXX":
        if (label !== "" && label !== undefined) {
          newImputs[key] = [label];
        } else if (value !== "") {
          if (newImputs[key] !== undefined) {
            const ovaues = value.map((row) => {
              return { id: row, label: row };
            });
            newImputs[key] = ovaues;
          } else {
            newImputs[key] = [{ id: `${value}`, label: `${value}` }];
          }
        } else {
          newImputs[key] = value;
        }
        setInputValue(newImputs);
        break;

      default:
        break;
    }
  };

  const validateMetadata = () => {
    let validate = true;
    let message = "";
    metadatas.length > 0
      ? metadatas.forEach((metadata) => {
        if (metadata.BANOBLXX === "SI") {
          if (typeof jsonmetx[metadata.METIDXXX] === "undefined") {
            message = message + "Debe Diligenciar: " + metadata.METDESXX + " ";
            validate = false;
          }
        } else {
          if (typeof jsonmetx[metadata.METIDXXX] === "undefined") {
            updateJsonmetx(metadata.METIDXXX, "");
          }
        }
      })
      : (validate = false);
    dispatch(setObjectErrors({ message }));
    dispatch(setShowErrors(!validate));
    return validate;
  };

  const handleSubmit = async (event) => {
    setInProgress(true);
    setDisableButton(true);
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() && validateMetadata()) {
      let metSend = {};
      metadatas.forEach((metadata) => {
        metSend[`${metadata.METIDXXX}`] = jsonmetx[metadata.METIDXXX] === undefined ? "" : jsonmetx[metadata.METIDXXX];
      });
      const positions = [];
      for (let i = 0; i < file.length; i++) {
        const currentFile = file[i];
        const currentContentType = contentType[i];
        const currentBase64 = base64[i];
        const fileClass = new fileSlice(currentFile, currentBase64, currentContentType, indexFile + i);
        //Valido el size del archivo
        if (currentFile.size < process.env.REACT_APP_GENIO_FILE_SIZE) {
          await fileClass.saveFileBase64().then(async (response) => {
            const position = {
              EMPNITXX: company,
              AREAIDXX: area,
              SERIEIDX: serie,
              TIPOIDXX: type,
              JSONMETX: metSend,
              FILEINDE: response.keyFile,
              TYPEXXXX: currentContentType,
              NAMEXXXX: currentFile.name,
              SIZEKBXX: currentFile.size / 1000,
              ISBASE64: true,
              URLPRESI: "",
              INPROCES: false,
              PAGSPLIT: pagesSplit[i],
              IDARCHXX: 0,
              INTENTOS: 0,
            };
            positions.push(position);
            fileClass.addIndexFile();
          });
          dispatch(resetPagesSplit());
        } else {
          await fileClass.saveFileBlob().then((response) => {
            const position = {
              EMPNITXX: company,
              AREAIDXX: area,
              SERIEIDX: serie,
              TIPOIDXX: type,
              JSONMETX: metSend,
              FILEINDE: response.keyFile,
              TYPEXXXX: currentContentType,
              NAMEXXXX: currentFile.name,
              SIZEKBXX: currentFile.size / 1000,
              ISBASE64: false,
              URLPRESI: "",
              INPROCES: false,
              PAGSPLIT: pagesSplit[i],
              IDARCHXX: 0,
              INTENTOS: 0,
            };
            positions.push(position);
            fileClass.addIndexFile();
          });
          dispatch(resetPagesSplit());
        }
      }
      dispatch(addArrayToQueue(positions));
      if (isClear) {
        resetValue();
      } else {
        reset();
      }
    } else {
      setValidated(true);
      event.preventDefault();
    }
    setDisableButton(false);
  };

  const resetValue = () => {
    setValidated(false);
    setFormData(false);
    setBase64([]);
    const newJsonmetx = { ...jsonmetx };
    for (const key in jsonmetx) {
      if (key !== "CLIIDXXX" && key !== "DOCNROXX") {
        if (key !== "PROIDXXX" && key !== "TRANINTX") {
          newJsonmetx[key] = "";
        }
      }
    }
    const fileInput = fileInputRef.current;
    fileInput.value = null;
    setJsonmetx(newJsonmetx);
    const dataFor = document.getElementById("idForm");
    for (let i = 0; i < dataFor.elements.length; i++) {
      const field = dataFor.elements[i];
      if (i > 3) {
        if (field.name !== "CLIIDXXX" && field.name !== "DOCNROXX") {
          if (field.type === "checkbox") {
            field.checked = false;
          }
          field.value = "";
        }
      }
    }
  };

  const reset = () => {
    setIsClear(false);
    setValidated(false);
    document.getElementById("idForm").reset();
    setCompany("");
    setArea("");
    setSerie("");
    setType("");
    setAreas({});
    setSeries({});
    setTypes({});
    setJsonmetx({});
    setFile();
    setContentType("");
    setBase64([]);
    dispatch(resetPagesSplit());
    setInputValue([]);
    setUseParams(false)
    navigate("/")
  };

  //useEffect for companys
  useEffect(() => {
    if (company !== "") {
      let preAreas = dataPermission.filter((SYS00013) => {
        return SYS00013.EMPNITXX === company.toString() ?? SYS00013;
      });
      setAreas(preAreas[0].GEST0001);
      if (!useParams) {
        setJsonmetx({});
      }
    } else {
      setAreas({});
      setSeries({});
    }
  }, [company, dataPermission]);

  //useEffect for areas
  useEffect(() => {
    if (area !== "" && Object.entries(areas).length > 0) {
      let preSeries = areas.filter((GEST0001) => {
        return GEST0001.AREAIDXX === parseInt(area) ?? GEST0001;
      });
      setSeries(preSeries[0]?.GEST0002);
      if (!isClear && !useParams) {
        setJsonmetx({});
      }
    } else {
      setSeries({});
      setTypes({});
    }
    if (!isClear && !useParams) {
      setInputValue([]);
    }
  }, [area, areas]);

  //useEffect to series
  useEffect(() => {
    if (serie !== "" && typeof series !== "undefined" && Object.entries(series).length > 0) {
      let preType = series.filter((GEST0002) => {
        return GEST0002.SERIEIDX === parseInt(serie) ?? GEST0002;
      });
      setTypes(preType[0]?.GEST0003);
      if (!isClear && !useParams) {
        setJsonmetx({});
      } else {
        switch (area) {
          case "100":
          case "101":
            if (!isClear && !useParams) {
              resetValue();
            }
            break;
          default:
            if (!useParams) {
              setJsonmetx({});
              setInputValue([]);
            }
            break;
        }
      }
    } else {
      setTypes({});
    }
    if (!isClear && !useParams) {
      setInputValue([]);
    }
  }, [serie, series]);

  //useEffect to types
  useEffect(() => {
    if (type !== "" && typeof types !== "undefined" && Object.entries(types).length > 0) {
      let preMetadatas = types.filter((GEST0003) => {
        return GEST0003.TIPOIDXX === parseInt(type) ?? GEST0003;
      });
      setMetadatas(preMetadatas[0]?.GEST0004);
      if (!isClear && !useParams) {
        setJsonmetx({});
      } else if (!useParams) {
        resetValue();
      }
    } else if (!useParams) {
      setMetadatas({})
    }
    if (!isClear && !useParams) {
      setInputValue([]);
    }
  }, [type, types]);
  //contar paginas de un pdf
  const onFileChange = async (file, index) => {
    try {
      const numPages = await countPages(file);
      // console.log("Numero de paginas: ", numPages);
      setListPages((list) => ({ ...list, [index]: numPages }));
    } catch (error) {
      console.log("Error al contar las paginas: ", error);
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit} id="idForm">
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={3}>
          <Form.Label>Empresa: *</Form.Label>
          <Form.Select
            value={company}
            name="EMPDESXX"
            required
            onChange={(e) => {
              setCompany(e.target.value);
              dispatch(setEmpnitxx(e.target.value));
            }}
          >
            <option value={""}>[SELECCIONE]</option>
            {dataPermission.map((SYS00013) => {
              return (
                <option value={SYS00013.EMPNITXX} key={SYS00013.EMPNITXX}>
                  {SYS00013.EMPDESXX}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar una Empresa</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>Proceso: *</Form.Label>
          <Form.Select
            required
            value={area}
            name="AREAIDXX"
            onChange={(e) => {
              setArea(e.target.value);
              dispatch(setAreaSelected(e.target.value));
            }}
          >
            <option value={""}>[SELECCIONE]</option>
            {areas?.length > 0
              ? areas?.map((GEST0001) => {
                return (
                  <option value={GEST0001.AREAIDXX} key={GEST0001.AREAIDXX}>
                    {GEST0001.AREADESX}
                  </option>
                );
              })
              : ""}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar un Area</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>Serie: *</Form.Label>
          <Form.Select
            value={serie}
            required
            name="SERIEIDX"
            onChange={(e) => {
              setSerie(e.target.value);
              dispatch(setSerieSelected(e.target.value));
            }}
          >
            <option value={""}>[SELECCIONE]</option>
            {series?.length > 0
              ? series?.map((GEST0002) => {
                return (
                  <option value={GEST0002.SERIEIDX} key={GEST0002.SERIEIDX}>
                    {GEST0002.SERIEDES}
                  </option>
                );
              })
              : ""}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar una Serie</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>Tipo: *</Form.Label>
          <Form.Select
            value={type}
            required
            name="TIPOIDXX"
            onChange={(e) => {
              setType(e.target.value);
              dispatch(setTypeSelected(e.target.value));
            }}
          >
            <option value={""}>[SELECCIONE]</option>
            {types?.length > 0
              ? types?.map((GEST0003) => {
                return (
                  <option value={GEST0003.TIPOIDXX} key={GEST0003.TIPOIDXX}>
                    {GEST0003.TIPODESX}
                  </option>
                );
              })
              : ""}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar un Tipo</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="align-items-end">
        {metadatas?.length > 0
          ? metadatas?.map((GEST0004) => {
            let width = 3;
            switch (GEST0004.METTIPXX) {
              case "LISTASELECCION":
              case "LISTASELECCIONFUNCION":
              case "LISTASELECCIONFUNCIONMULTIPLE":
                width = 6;
                break;
              default:
                width = 3;
                break;
            }
            return (
              <Col md={width} key={GEST0004.METIDXXX}>
                <Metadata
                  GEST0004={GEST0004}
                  setJsonmetx={updateJsonmetx}
                  inputValue={inputValue}
                  jsonmetx={jsonmetx}
                />
              </Col>
            );
          })
          : ""}
      </Row>
      <Row className="mt-3">
        <Form.Label>Archivo: *</Form.Label>
        <Form.Control
          type="file"
          ref={fileInputRef}
          accept={dataUser.EXTENSXX}
          required
          multiple
          onChange={(e) => {
            setFile([]);
            setContentType([]);
            setBase64([]);
            for (let i = 0; i < e.target.files.length; i++) {
              const file = e.target.files[i];
              const f = new FileReader();
              f.onloadend = () => {
                if (f.result !== "") {
                  const contentType = file.type;
                  const base64 = f.result;
                  setFile((prevFiles) => [...prevFiles, file]);
                  setContentType((prevContentTypes) => [...prevContentTypes, contentType]);
                  setBase64((prevBase64s) => [...prevBase64s, base64]);
                  if (contentType === "application/pdf") {
                    setIsPdf(true);
                    onFileChange(e.target.files[i], i);
                  } else {
                    setIsPdf(false);
                  }
                  setIsSplitPdf(false);
                }
              };
              f.readAsDataURL(file);
            }
            setFormData(true);
          }}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">Debe Adjuntar un Archivo</Form.Control.Feedback>
      </Row>

      {base64.length > 0 && isPdf && (
        <>
          <br />
          <Row>
            <Col>
              Dividir PDF{" "}
              <Form.Check
                type="radio"
                label="SI"
                name="SPLITPDF"
                value="SI"
                inline
                onClick={() => {
                  setIsSplitPdf(true);
                }}
              />
              <Form.Check
                type="radio"
                label="NO"
                name="SPLITPDF"
                value="NO"
                inline
                onClick={() => {
                  setIsSplitPdf(false);
                  dispatch(resetPagesSplit());
                }}
              />
            </Col>
          </Row>
          <br />
        </>
      )}
      {isSplitPdf && (
        <Row>
          {base64.map((item, index) => (
            <Col key={index}>
              <Splitpdf numPages={listPages[index]} index={index} nameFile={file[index].name} />
            </Col>
          ))}
        </Row>
      )}
      <Row md={12} className="mt-3 mb-3">
        <Col md={2}>
          <Button
            className="w-100"
            disabled={disableButton}
            onClick={() => {
              reset();
            }}
          >
            LIMPIAR
          </Button>
        </Col>
        <Col md={2}>
          <Button
            type="submit"
            className="w-100"
            disabled={disableButton}
            onClick={() => {
              setIsClear(false);
            }}
          >
            <span>CREAR CARGUE</span>
          </Button>
        </Col>
        {formData ? (
          <Col md={2}>
            <Button
              disabled={disableButton}
              type="submit"
              className="w-100"
              onClick={() => {
                setIsClear(true);
              }}
            >
              CONSERVAR DATOS
            </Button>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </Form>
  );
};

export default Selectop;
